import { createTheme, adaptV4Theme } from "@mui/material/styles";

const theme = createTheme(adaptV4Theme({
  palette: {
    theme: "dark",
    main: "#000000",
    primary: {
      main: "#000000",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#000000",
      contrastText: "#000000",
    },
    text: {
      secondary: "#000000",
    },
    type: "dark",
    inputColor: "#ffffff",
    surfaceColor: "#ffffff00",
    platformColor: "#ffffff",
    backgroundColor: "#F5F7FA",
    green: "#5cc879",
    borderRadius: 5,
    defaultBorder: "1px solid rgba(18, 161, 205, 1)",
    jsonTheme: "brewer",
    reactJsonStyle: {
      borderRadius: 5,
      border: "1px solid rgba(18, 161, 205, 1)",
      padding: 5, 
      width: "100%",
    },
    textFieldStyle: {
      backgroundColor: "#ffffff",
      borderRadius: 5,
      border:'0.8px solid #12A1CD',
      boxShadow: "3px 4px 08px 0px rgba(18, 161, 205, .1)",
      color:'black'
      
    },
    tooltip: {
      backgroundColor: "#ffffff",
      color: "#000000",
      boxShadow: 1,
      fontSize: 11,
    },
    defaultImage: "/images/Shuffle_logo_new.png",
  },
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    useNextVariants: true,
    h1: {
      fontSize: 40,
      color: 'black'
    },
    h4: {
      fontSize: 30,
      fontWeight: 500,
      color: 'black'
    },
    h6: {
      fontSize: 22,
      color: 'black'
    },
    body1: {
      fontSize: 18,
      color: 'black'
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          style: {
            color: 'black !important',
            backgroundColor: 'white !important',
            margin: '0px auto !important',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'black',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          backgroundColor: 'white',
          color: 'black',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          '@font-face': [
            {
              fontFamily: 'roboto',
              fontStyle: 'normal',
              fontDisplay: 'swap',
              fontWeight: 300,
              src: `local('roboto'), local('roboto'), format('truetype')`,
              unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
            },
          ],
        },
      },
    },
  },
}));

export default theme;
